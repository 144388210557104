import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment.prod';
import { AuthService } from 'app/shared/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AboutUsService {
data: any;
 token = this._authService.getToken();
  parsed_token = JSON.parse(this.token);

  constructor(private http: HttpClient, private _authService: AuthService) { }

  AddAboutUs<T>(aboutUsModel: any): Observable<T> {
    const endpointUrl = environment.apiUrl + 'AddAboutUs';
    return this.http.post<T>(endpointUrl, aboutUsModel, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.parsed_token.token,
        'Content-Type': 'application/json'
    })
  });
  }

  GetAboutUs<T>(): Observable<T> {
    const endpointUrl = environment.apiUrl + 'GetAboutUs';
    return this.http.get<T>(endpointUrl, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.parsed_token.token,
        'Content-Type': 'application/json'
    })
  });
  }


  PushNotification<T>(message: string): Observable<T> {
    const endpointUrl = environment.apiUrl + 'SendNotificationToCustomer';
    return this.http.post<T>(endpointUrl, message, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.parsed_token.token,
        'Content-Type': 'application/json'
      })
    });
  }

}
