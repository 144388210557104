import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators'
import { environment } from '../../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class ImageUploaderService {

  constructor(private http: HttpClient) { }

  Upload<T>(formData: FormData): Observable<T> {
    const endpointUrl = environment.apiUrl + 'MediaUpload';
    return this.http.post<T>(endpointUrl, formData);
  }

}
