import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment.prod';
import { AuthService } from 'app/shared/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ParagraphService {
data: any;
 token = this._authService.getToken();
  parsed_token = JSON.parse(this.token);

  constructor(private http: HttpClient, private _authService: AuthService) { }


  GetParagraphs<T>(params: HttpParams): Observable<T> {
    const endpointUrl = environment.apiUrl + 'GetAllParagraph';
    return this.http.get<T>(endpointUrl, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.parsed_token.token,
        'Content-Type': 'application/json'
    }),
    params
  });
  }
 

  AddParagraphs<T>(banner: any): Observable<T> {
    const endpointUrl = environment.apiUrl + 'CreateParagraph';
    return this.http.post<T>(endpointUrl, banner, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.parsed_token.token,
        'Content-Type': 'application/json'
    })
  });
  }


  GetAllChapters<T>(): Observable<T> {
    const endpointUrl = environment.apiUrl + 'GetAllChaptersForDdl';
    return this.http.get<T>(endpointUrl, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.parsed_token.token,
        'Content-Type': 'application/json'
    })
  });
  }

 

  GetParagraphsById<T>(id: any): Observable<T> {
    const endpointUrl = environment.apiUrl + 'GetParagraphById/' + id;
    return this.http.get<T>(endpointUrl, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.parsed_token.token,
        'Content-Type': 'application/json'
    })
  });
  }

  UpdateParagraphs<T>(banner: any): Observable<T> {
    const endpointUrl = environment.apiUrl + 'UpdateParagraph';
    return this.http.put<T>(endpointUrl, banner, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.parsed_token.token,
        'Content-Type': 'application/json'
    })
  });
  }
 
  DeleteParagraph<T>(id: any): Observable<T> {
    const endpointUrl = environment.apiUrl + 'DeleteParagraph/' + id;
    return this.http.delete<T>(endpointUrl, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.parsed_token.token,
        'Content-Type': 'application/json'
    })
  });
  }

}
