export class Paragraph {
  public title: string;
  public chapterId: number; 
  public fromVerseId: number; 
  public toVerseId: number; 
    constructor(title?: string,chapterId?: number,fromVerseId?: number,toVerseId?: number
    ) {
      title = title;
      chapterId = chapterId;
      fromVerseId = fromVerseId;
      toVerseId = toVerseId;
  }
}
